import * as React from "react"
import { FollowImage } from "./images/follow-image"
import style from "./feature.module.scss"

const Feature = ({ icon, title, children, className }) => (
  <article className={className}>
    <FollowImage filename={icon} className={style.image} />
    <h1 className={style.title}>{title}</h1>
    <p className={style.copy}>{children}</p>
  </article>
)

export { Feature }
