import * as React from "react"
import { Feature } from "./feature"
import WideImage from "./images/wide-image"
import style from "./features.module.scss"

const Features = () => (
  <section className={style.container}>
    <WideImage filename="bg-features.png" className={style.backdropImage} />
    <Feature
      className={style.shareFeature}
      icon="icon-share.png"
      title={
        <>
          <em>Share</em> your favorites
        </>
      }
    >
      Post your favorite spots from around the world. Pao will organize them
      into your own beautiful profile.
    </Feature>
    <Feature
      icon="icon-simple-ui.png"
      className={style.simpleFeature}
      title={
        <>
          Use a <em>simple</em> interface
        </>
      }
    >
      No information overload. Sleek design and minimalism is what Pao is all
      about.
    </Feature>
    <Feature
      className={style.findFeature}
      icon="icon-search.png"
      title={
        <>
          <em>Find</em> new favorites
        </>
      }
    >
      Search through hidden gems only, no tourist traps or negative reviews.
      Only the best.
    </Feature>
    <Feature
      className={style.joinFeature}
      icon="icon-social-media.png"
      title={
        <>
          <em>Join</em> a community
        </>
      }
    >
      Pao users are doers who just want to find and share the coolest spots as
      they experience the world.
    </Feature>
    <WideImage
      filename="about-us-phone-straight.png"
      className={style.imageFeature}
    />
  </section>
)

export { Features }
