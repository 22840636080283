import React from "react"
import { ExternalLink } from "../components/external-link"
import { Features } from "../components/features"
import WideImage from "../components/images/wide-image"

import Layout from "../components/layout"
import AppStoreImage from "../components/images/app-store-badge"
import { Statistic } from "../components/statistic"
import { appStoreLink } from "../constants"
import style from "./about-us.module.scss"

const IndexPage = () => (
  <Layout backgroundImage="bg-shop.png">
    <section className={style.pageHeader}>
      <h1 className={style.pageHeaderText}>Keep Local Alive</h1>
      <p className={style.pageHeaderDetailText}>
        This is an initiative to help local businesses affected by the
        coronavirus, and to help people stay sane when they are mainly stuck at
        home. Our company the Pao App is all about helping people find and share
        their favorite hidden gems around the world, and most often these are
        locally owned businesses. Due to the virus, many of these small
        businesses are facing a survival crisis. The Pao Community doesn’t want
        to lose their favorite spots, so we want to do our part in supporting
        them now. Scroll down to learn more about Pao!
      </p>
    </section>
    <section className={style.appSection}>
      <WideImage
        filename="bg-download-app.png"
        className={style.appSectionBackdrop}
      />
      <div className={style.appSectionContents}>
        <div className={style.appSectionContentCopy}>
          <h1 className={style.appSectionContentTitle}>
            An app for the world's <em>hidden gems</em>.
          </h1>
          <p className={style.appSectionContentDetail}>
            Pao (say "pow") shows you the coolest spots in whatever city you're
            in.
          </p>
          <ExternalLink href={appStoreLink} target="_blank">
            <AppStoreImage />
          </ExternalLink>
        </div>
        <div className={style.appSectionContentsPhone}>
          <WideImage
            filename="about-us-phone-angled.png"
            className={style.appSectionPhone}
          />
        </div>
      </div>
    </section>
    <Features />
    <section className={style.statsSection}>
      <Statistic
        title="Cities"
        value="200"
        background="bg-cities.png"
        icon="icon-cities.png"
      />
      <Statistic
        title="Hidden Gems"
        value="15,000"
        dropDown
        background="bg-gems.png"
        icon="icon-gem.png"
      />
      <Statistic
        title="Countries"
        value="90"
        background="bg-countries.png"
        icon="icon-flag.png"
      />
    </section>
  </Layout>
)

export default IndexPage
