import * as React from "react"
import { FollowImage } from "./images/follow-image"
import WideImage from "./images/wide-image"
import style from "./statistic.module.scss"

const Statistic = ({ background, value, title, icon, dropDown }) => (
  <div className={dropDown ? style.dropped : style.item}>
    <WideImage filename={background} className={style.backdrop} />
    <div className={style.shadow} />
    <div className={style.content}>
      <FollowImage filename={icon} className={style.icon} />
      <h2 className={style.value}>+{value}</h2>
      <p className={style.label}>{title}</p>
    </div>
  </div>
)

export { Statistic }
